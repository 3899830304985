<template>
  <q-form ref="editForm">
    <!-- 기본정보 -->
    <c-card title="LBLBASEINFO" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <!-- 삭제 -->
          <c-btn 
            v-if="editable&&popupParam.eduCourseId" 
            label="LBLREMOVE" 
            icon="delete_forever" 
            @btnClicked="removeInfo"
          />
          <!-- 저장 -->
          <c-btn
            v-if="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="educationInfo"
            :mappingType="saveType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-4 col-sm-4 col-md-6 col-lg-6 col-xl-6">
          <!-- 교육과정명 -->
          <c-text
            required
            :editable="editable"
            label="LBL0002811"
            name="educationCourseName"
            v-model="educationInfo.educationCourseName">
          </c-text>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
          <!-- 교육종류1 -->
          <c-select
            required
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="LBL0002933"
            v-model="educationInfo.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 교육종류2 -->
          <c-select
            required
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="LBL0002934"
            v-model="educationInfo.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant 
            required
            :editable="editable" 
            :isEnterprise="true"
            type="edit" 
            name="plantCd" 
            v-model="educationInfo.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 교육구분 -->
          <c-select
            required
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="LBL0002935"
            v-model="educationInfo.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-1 col-lg-1 col-xl-1">
          <!-- 법정교육여부 -->
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            label="LBL0002800"
            name="legalEducationFlag"
            v-model="educationInfo.legalEducationFlag"
            @datachange="datachange">
          </c-checkbox>
        </div>
        <!-- <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            required
            :editable="editable"
            type="time"
            :minuteStep="10"
            label="교육시간"
            name="educationTime"
            v-model="educationInfo.educationTime"
          />
        </div> -->
        <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
          <!-- 교육주기 -->
          <c-select
            :required="true"
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_CYCLE_CD"
            itemText="codeName"
            itemValue="code"
            name="educationCycle"
            label="LBL0002812"
            v-model="educationInfo.educationCycle"
          ></c-select>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
          <!-- 원 -->
          <!-- 예상 교육비 -->
          <c-text
            :suffix="$label('LBL0002813')"
            type="number"
            :editable="editable"
            label="LBL0002803"
            name="estimatedEducationExpenses"
            v-model="educationInfo.estimatedEducationExpenses">
          </c-text>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-6 col-lg-6 col-xl-6">
          <!-- 주요 대상자 -->
          <c-text
            :editable="editable"
            label="LBL0002814"
            name="mainTargetAudience"
            v-model="educationInfo.mainTargetAudience">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <!-- 학습목적 -->
          <c-text
            required
            :editable="editable"
            label="LBL0002937"
            name="educationPurpose"
            v-model="educationInfo.educationPurpose">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 관련 법규 -->
          <c-multi-select
            required
            :editable="editable"
            :isArray="false"
            codeGroupCd="RELATED_LAWS_CD"
            itemText="codeName"
            itemValue="code"
            label="LBL0002804"
            name="relationLaws"
            maxValues="3"
            v-model="educationInfo.relationLaws">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <!-- 사용여부 -->
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            label="LBLUSEFLAG"
            name="useFlag"
            v-model="educationInfo.useFlag">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 문제풀이여부 -->
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            label="LBL0002815"
            name="eduQuestionFlag"
            v-model="educationInfo.eduQuestionFlag">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 문제풀이SET -->
          <c-edu-quiz 
            v-if="educationInfo.eduQuestionFlag == 'Y'"
            :required="educationInfo.eduQuestionFlag == 'Y'"
            :editable="editable"
            label="LBL0002816"
            name="eduQuestionMstId"
            v-model="educationInfo.eduQuestionMstId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 통과기준점수 -->
          <c-text
            v-if="educationInfo.eduQuestionFlag == 'Y'"
            :required="educationInfo.eduQuestionFlag == 'Y'"
            :editable="editable"
            type="number"
            label="LBL0002817"
            name="eduQuestionPassScore"
            v-model="educationInfo.eduQuestionPassScore">
          </c-text>
        </div>
      </template>
    </c-card>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 교육내용 -->
      <c-table
        ref="table"
        title="LBL0002820"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="educationInfo.eduCourseSubjectList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :isExcelDown="false"
        :editable="editable"
        rowKey="subjectNo"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 추가 -->
            <c-btn v-if="editable" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addItem" />
            <!-- 제외 -->
            <c-btn v-if="editable && educationInfo.eduCourseSubjectList.length > 0" label="LBLEXCEPT" :showLoading="false" icon="remove" @btnClicked="removeItem" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </q-form>
</template>

<script>

import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-curriculumn-info',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduCourseId: '',
      }),
    },
  },
  data() {
    return {
      educationInfo: {
        eduCourseId: '',  // 교육과정 일련번호
        plantCd: '',  // 사업장 코드
        educationCourseName: '',  // 교육과정명
        educationKindCdLarge: null,  // 교육종류1
        educationKindCdSmall: null,  // 교육종류2
        educationTypeCd: null,  // 전사자체/부서자체/사내위탁/사외교육/연수/기타
        legalEducationFlag: 'Y',  // 법정교육 여부
        educationTime: '',  // 교육시간
        educationCycle: null,  // 주기
        educationPurpose: '',  // 학습목적
        mainmainTargetAudience: '',  // 기술
        estimatedEducationExpenses: '',  // 예상 교육비
        relationLaws: '',  // 관련 법규
        useFlag: 'Y',
        eduQuestionFlag: 'N',
        eduQuestionMstId: '',
        eduQuestionPassScore: 0,
        regUserId: '',
        chkUserId: '',

        eduCourseSubjectList: [], // 과정별 교육과목
        deleteEduCourseSubjectList: [], // 과정별 교육과목
      },
      grid: {
        columns: [
          {
            name: 'subjectName',
            field: 'subjectName',
            // 과목 및 세부내용
            label: 'LBL0002818',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'educationTime',
            field: 'educationTime',
            // 교육시간
            label: 'LBL0002932',
            type: 'datetime',
            minuteStep: 10,
            style: 'width:50px',
            align: 'center',
            sortable: false
          },
          {
            name: 'instructorName',
            field: 'instructorName',
            type: 'text',
            // 강사명
            label: 'LBL0002819',
            style: 'width:100px',
            align: 'left',
            sortable: false
          },
        ],
        data: [],
        height: '450px'
      },
      editable: true,
      saveUrl: 'transactionConfig.sop.edu.course.insert.url',
      saveType: 'POST',
      isSave: false,
      checkUrl: '', 
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.edu.course.get.url;
      this.checkUrl = selectConfig.sop.edu.course.check.url;
      this.insertUrl = transactionConfig.sop.edu.course.insert.url;
      this.updateUrl = transactionConfig.sop.edu.course.update.url;
      this.deleteUrl = transactionConfig.sop.edu.course.delete.url;
      // list setting
      this.getDetail();
      // this.setHeader();
    },
    removeInfo() {
      this.$http.url = selectConfig.sop.edu.result.list.url + '/count'
      this.$http.type = 'GET';
      this.$http.param = {
        eduCourseId: this.popupParam.eduCourseId,
      };
      this.$http.request((_result) => {
        if (_result.data > 0) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            // 해당 교육과정은 사용중인 교육계획이 존재하여\r\n삭제할 수 없습니다.
            message: 'MSG0000850', 
            type: 'info', // success / info / warning / error
          });
        } else {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGREMOVE', // 삭제하시겠습니까?,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.$format(this.deleteUrl, this.popupParam.eduCourseId);
              this.$http.type = 'DELETE';
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                
                this.$emit('closePopup')
              },);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        }
      },);
    },
    getDetail() {
      if (this.popupParam.eduCourseId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = this.$_.clone(_result.data);

          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.updateMode = true;
        },);
      }
    },
    addItem() {
      this.educationInfo.eduCourseSubjectList.push({
        eduCourseId: this.popupParam.eduCourseId,
        subjectNo: uid(),
        subjectName: '', // 교육명
        subjectDetailName: '', // 세부내용
        educationTime: '', // 교육시간
        instructorName: '', // 강사명
        editFlag: 'C',
      })
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.educationInfo.deleteEduCourseSubjectList) {
            this.educationInfo.deleteEduCourseSubjectList = []
          }
          if (this.$_.findIndex(this.educationInfo.deleteEduCourseSubjectList, { subjectNo: item.subjectNo }) === -1
            && item.editFlag !== 'C') {
              this.educationInfo.deleteEduCourseSubjectList.push(item)
          }
          this.educationInfo.eduCourseSubjectList = this.$_.reject(this.educationInfo.eduCourseSubjectList, item)
        })
      }
    },
    saveInfo() {
      if (this.popupParam.eduCourseId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      if (this.educationInfo.eduQuestionFlag == 'N') {
        this.educationInfo.eduQuestionMstId = null;
        this.educationInfo.eduQuestionPassScore = null;
      } else {
        if (this.educationInfo.eduQuestionPassScore == '0') {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: 'MSG0000851', // 문제풀이 통과 기준점수를 입력하세요.
            type: 'warning', // success / info / warning / error
          });
          return;
        }
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
            this.$http.url = this.$format(this.checkUrl, this.educationInfo.plantCd, this.educationInfo.educationCourseName, this.educationInfo.eduCourseId ? this.educationInfo.eduCourseId : 'TEMP');
            this.$http.type = 'GET';
            this.$http.request((_result) => {
              if (_result.data > 0) {
                window.getApp.$emit('ALERT', {
                  title: 'LBLGUIDE', // 안내
                  message: 'MSG0000852', // 사업장 내 이미 존재하는 과정명입니다.
                  type: 'warning', // success / info / warning / error
                });
                return;
              } else {
                window.getApp.$emit('CONFIRM', {
                  title: 'LBLCONFIRM',
                  message: 'MSGSAVE', // 저장하시겠습니까?
                  // TODO : 필요시 추가하세요.
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    this.educationInfo.regUserId = this.$store.getters.user.userId;
                    this.educationInfo.chgUserId = this.$store.getters.user.userId;
                  
                    this.isSave = !this.isSave;
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                  },
                  });
                }
            },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.eduCourseId) {
        this.$emit('changeStatus')
        this.popupParam.eduCourseId = result.data;
      }
      this.getDetail();
    },
    datachange() {
      if (this.educationInfo.legalEducationFlag !== 'Y') {
        this.educationInfo.educationCycle = null
        // window.getApp.$emit('CONFIRM', {
        //   title: 'LBLCONFIRM',
        //   message: '법정교육여부를 NO로 체크할 시 법정교육주기는 초기화됩니다.',
        //   // TODO : 필요시 추가하세요.
        //   type: 'info', // success / info / warning / error
        //   // 확인 callback 함수
        //   confirmCallback: () => {
        //   },
        //   // 취소 callback 함수
        //   cancelCallback: () => {
        //   },
        // });
      }
    }
  }
};
</script>
